<template>
  <div>
    <h1>About Us</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris convallis elit et sem pulvinar, ac lacinia lectus efficitur. Praesent quam arcu, egestas ut pulvinar id, aliquet at mi. Quisque ultricies odio mauris, sed sagittis mauris facilisis ac. In pulvinar nunc et ipsum sagittis, sit amet finibus lectus pretium.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutComponent',
}
</script>