<template>
  <section id="home" class="hero">
    <div class="hero-content">
      <h1>Welcome to SendMoneySouthAfrica.com</h1>
      <p>Send money to South Africa quickly, securely, and with the best rates.</p>
      <a href="#features" class="cta-button">Learn More</a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroComponent'
};
</script>

<style>
.hero {
  background: url('../assets/mountain.webp') no-repeat center center/cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.hero-content {
  max-width: 600px;
  margin-top: -250px; /* Adjusted margin-top */

}

.cta-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: #ff6f61;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}
</style>
