<template>
  <div>{{ value }}</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const value = ref("");

onMounted(async () => {
  const { text } = await (await fetch("/api/message")).json();
  value.value = text;
});
</script>

<style scoped>
/* add your css style here */
</style>