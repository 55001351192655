<template>
  <section id="faq" class="faq">
    <h2>Frequently Asked Questions</h2>
    <div class="faq-items">
      <div class="faq-item">
        <h3>How do I send money to South Africa?</h3>
        <p>You can send money to South Africa using our online platform. Simply create an account, enter the recipient's details, and make the payment.</p>
      </div>
      <div class="faq-item">
        <h3>What are the fees for sending money?</h3>
        <p>Our fees are among the lowest in the industry. The exact fee depends on the amount and payment method, but you can always see the fee before confirming the transaction.</p>
      </div>
      <div class="faq-item">
        <h3>How long does it take for the money to arrive?</h3>
        <p>Transfers typically take a few minutes, but can take up to a few hours depending on the payment method and banking hours.</p>
      </div>
      <div class="faq-item">
        <h3>Is it safe to send money through your platform?</h3>
        <p>Yes, we use advanced security measures to protect your money and personal information. All transactions are encrypted and monitored for fraud.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FAQComponent'
};
</script>

<style>
.faq {
  padding: 4rem 2rem;
}

.faq h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.faq-items {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 2rem;
}

.faq-item h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.faq-item p {
  font-size: 1rem;
}
</style>
