import { createRouter, createWebHistory } from 'vue-router';
import { onBeforeMount } from 'vue';
import Home from './components/HomeComponent.vue';
import About from './components/AboutComponent.vue';

// Function to update meta tags and document title
const updateMeta = (to) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (to.meta.metaTags) {
        to.meta.metaTags.forEach(metaTag => {
            let element = document.querySelector(`meta[name='${metaTag.name}']`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', metaTag.name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', metaTag.content);
        });
    }
};

// Define your routes
const routes = [
    {
        path: '/',
        name: 'Tester',
        component: Home,
        meta: {
            title: 'Send Money South Africa Online',
            metaTags: [
                { name: 'description', content: 'We help you send money back to South Africa online with ease.' },
                { name: 'keywords', content: 'send, transfer, money, south africa, online ' }
            ]
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: 'About Us',
            metaTags: [
                { name: 'description', content: 'Learn more about us on this page.' },
                { name: 'keywords', content: 'about, company, information' }
            ]
        }
    }
];

// Create router instance
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// Update metadata before each route change
router.beforeEach((to, from, next) => {
    updateMeta(to);
    next();
});

// Update metadata on initial load
onBeforeMount(() => {
    updateMeta(router.currentRoute.value);
});

export default router;
