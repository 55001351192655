<template>
  <div id="app">
    <header>
      <nav>
        <div class="logo">Send Money To South Africa Online</div>
        <ul class="nav-links">
          <li><a href="#home">Home</a></li>
          <li><a href="#features">Features</a></li>
          <li><a href="#faq">FAQ</a></li>
<!--          <li><a href="#contact">Contact</a></li>-->
        </ul>
      </nav>
    </header>
    <main>
      <HeroComponent />
      <FeaturesComponent />
      <FAQComponent />
    </main>
    <footer>
      <div class="footer-content">
        <p>&copy; 2024 SendMoneySouthAfrica.com. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import HeroComponent from './components/HeroComponent.vue';
import FeaturesComponent from './components/FeaturesComponent.vue';
import FAQComponent from './components/FAQComponent.vue';

export default {
  name: 'App',
  components: {
    HeroComponent,
    FeaturesComponent,
    FAQComponent
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

header {
  background: #333;
  color: #fff;
  padding: 1rem 0;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

main {
  padding: 2rem;
}

footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
}

.footer-content p {
  margin: 0;
}
</style>



<!--<template>-->
<!--  <div id="app">-->
<!--    <img alt="Vue logo" src="./assets/logo.png">-->

<!--    <nav>-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </nav>-->

<!--    <router-view/>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--// No need to import HomePage and AboutPage here because they are handled by vue-router-->
<!--export default {-->
<!--  name: 'App'-->
<!--}-->
<!--</script>-->

<!--<style>-->
<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--  margin-top: 60px;-->
<!--}-->
<!--</style>-->