<template>
  <section id="features" class="features">
    <h2>Features</h2>
    <div class="feature-cards">
      <div class="card">
        <h3>Low Fees</h3>
        <p>We offer the lowest transfer fees to help you save money.</p>
      </div>
      <div class="card">
        <h3>Fast Transfers</h3>
        <p>Get your money to South Africa in minutes, not days.</p>
      </div>
      <div class="card">
        <h3>Secure Transactions</h3>
        <p>Our platform ensures your money is safe and secure.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesComponent'
};
</script>

<style>
.features {
  padding: 4rem 2rem;
  background: #f4f4f4;
  text-align: center;
}

.features h2 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
}

.card {
  background: #fff;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 30%;
}

.card h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
</style>
